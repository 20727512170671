.MainDash{
    /* padding-top: 1rem; */
    display: flex;
    flex-direction: column;
    height: 110vh;
    margin: 0;
}

.HeaderWelcome{
    margin: 0;
    font-size:medium;

}
@media screen and (max-width: 1000px) {
    .MainDash{
        justify-content:flex-start;
        margin-top: 2rem;
    }
}

@media screen and (max-width: 768px)
{
    .MainDash{
        align-items: center;
    }
}
