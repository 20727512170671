.Sidebar {
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  height: 100vh;
}

.logo {
  display: flex;
  height: 5rem;
  font-weight: bold;
  font-size: 22px;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  height: 4%;
}

.crablogo {
  width: 50px;
  height: 50px;
}

.logo > span > span {
  color: var(--pink);
}

.logo > img {
  width: 3rem;
  height: 3rem;
}

.menu {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 2.5rem;
  margin-left: 2rem;
  position: relative;
  border-radius: 0.7rem;
  font-size: 14px;
}

.menuItem:hover {
  cursor: pointer;
}

.menu .menuItem:last-child {
  position: relative;
  margin-top: 2rem;
}

.active {
  background: var(--activeItem);
  margin-left: 0;
}

.active::before {
  content: "";
  width: 8px;
  height: 100%;
  background: var(--pink);
  margin-right: calc(1rem - 8px);
}

.bars {
  display: none; /* Hide the bars initially */
}

.sidebarOpen .bars {
  display: flex; /* Show the bars when sidebarOpen class is present */
}

.open-icon,
.close-icon {
  font-size: 20px;
}

.close-icon {
  color: #fff;
}

/* Tablets */
@media screen and (max-width: 1200px) {
  .menuItem > span {
    display: none;
  }
  .logo {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .Sidebar {
    position: fixed;
    z-index: 9;
    background: #ffe0e0f8;
    width: 55%;
    padding-right: 1rem;
    height: 100%;
    transition: width 0.3s ease-in-out;
    left: -55%; /* Add this line to hide the sidebar initially */
    border-radius: 10px;
    /* --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08); */
  }

  .sidebarOpen {
    width: 55%; /* Show the sidebar when it is open */
    left: 0; /* Adjust the left value to show the sidebar */
    overflow: hidden;
  }

  .menuItem > span {
    display: block;
  }

  .logo {
    display: flex;
  }

  .menu .menuItem:last-child {
    position: relative;
    margin-top: 2rem;
  }

  .bars {
    display: flex;
    position: fixed;
    top: 2rem;
    left: 1rem;
    background: transparent;
    padding: 10px;
    border-radius: 10px;
    z-index: 9;
    cursor: pointer;
  }

  .close {
    top: 0.5rem;
    right: 0.5rem;
    font-size: 20px;
    color: #000000;
    cursor: pointer;
  }
}
